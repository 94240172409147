import { GetPayersResponse } from "./models/get_payers_response";
import { GetChartDataResponse } from "./models/get_chart_data_response";
import { GetWebAppUserInfoResponse } from "./models/get_webapp_user_info_response";
import {LoginResponse} from "./models/login_response";
import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {GetGroupsResponse} from "./models/get_groups_response";
import {SuggestIdResponse} from "./models/suggest_id_response";
import {CreatePayerRequest} from "./models/create_payer_request";
import {CreatePayerResponse} from "./models/create_payer_response";
import {IGetPaymentsResponse} from "./models/get_payments_reponse";
import {IUpdatePayerRequest} from "./models/update_payer_request";
import {IUpdatePayerResponse} from "./models/update_payer_response";

export class Api {

    static #GetEndpoint() : string {
        //return "https://app-m-payments-dev.wc";
        //return "https://127.0.0.1:5443";
        const hostname = window?.location?.hostname;
        return `https://${hostname}`;
    }

    static async GetChartData() : Promise<GetChartDataResponse> {

        const response = await fetch(`${Api.#GetEndpoint()}/api/v1/tournament`);

        const json = await response.json();
        return json;
    }

    static async GetPayer(payerId: string) : Promise<GetPayersResponse> {
        const url = `${Api.#GetEndpoint()}/api/v1/payers?payerId=${payerId}`;
        const headers = Api.GetHeaders();
        return (await Api.BaseRequest("GET", url, undefined, headers, false))!;
    }

    static async GetAllPayers() : Promise<GetPayersResponse> {
        const url = `${Api.#GetEndpoint()}/api/v1/payers`;
        const headers = Api.GetHeaders();
        return (await Api.BaseRequest("GET", url, undefined, headers, false))!;
    }

    static async GetWebAppUserInfo(telegramId: number, initData: string) : Promise<GetWebAppUserInfoResponse> {

        const response = await fetch(`${Api.#GetEndpoint()}/api/v1/webapp/userInfo`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                telegramId: telegramId,
                initDataSource: initData
            })
        });

        const json = await response.json();
        return json;
    }

    static async Login(username: string, password: string) : Promise<LoginResponse> {

        const url = `${Api.#GetEndpoint()}/api/v1/access/login`;

        const body = {
            username,
            password
        }

        const headers = { }

        return (await Api.BaseRequest("POST", url, body, headers, true))!;
    }

    static async GetGroups() : Promise<GetGroupsResponse> {
        const url = `${Api.#GetEndpoint()}/api/v1/Payers/Groups`;
        const headers = Api.GetHeaders();
        return (await Api.BaseRequest("GET", url, undefined, headers, false))!;
    }

    static async GetSuggestedPayerId() : Promise<SuggestIdResponse> {
        const url = `${Api.#GetEndpoint()}/api/v1/Payers/SuggestId`;
        const headers = Api.GetHeaders();
        return (await Api.BaseRequest("GET", url, undefined, headers, false))!;
    }

    // Todo: why not used
    static async CreatePayer(request: CreatePayerRequest) : Promise<CreatePayerResponse> {
        const url = `${Api.#GetEndpoint()}/api/v1/Payers/Create`;
        const headers = Api.GetHeaders();
        return (await Api.BaseRequest("POST", url, request, headers, false))!;
    }

    static async UpdatePayer(request: IUpdatePayerRequest) : Promise<IUpdatePayerResponse> {
        const url = `${Api.#GetEndpoint()}/api/v1/Payers/Update`;
        const headers = Api.GetHeaders();
        return (await Api.BaseRequest("POST", url, request, headers, false))!;
    }

    static async GetPayments(payerId: string) : Promise<IGetPaymentsResponse> {
        const url = `${Api.#GetEndpoint()}/api/v1/Payments?payerId=${payerId}`;
        const headers = Api.GetHeaders();
        return (await Api.BaseRequest("GET", url, undefined, headers, false))!;
    }

     private static async BaseRequest(
         method: string,
         url: string,
         body?: object,
         headers?: object,
         doNotRedirect?: boolean)   {

         const requestConfig: AxiosRequestConfig = {
             url: url,
             method: method,
             headers: {
                 ...headers,
                 "Accept": "application/json",
                 "Content-Type": "application/json"
             },
             data: (method !== "GET") ? JSON.stringify(body) : undefined
         };

         try
         {
             const response = await axios.request(requestConfig);
             return response.data;
         }
         catch(error: any)
         {
             if (error.response.status === 401) {

                 localStorage.removeItem("token");

                 if (!doNotRedirect) {
                     window.location.replace("/admin");
                     return;
                 }
             }

             throw error;
         }
     }

     private static GetHeaders(): object {

         const token = localStorage.getItem("token") ?? "";

         return {
             "Authorization": `Bearer ${token}`
         };
    }
}
