import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';
import AdminApp from "./Admin/AdminApp";
import Layout from './Admin/Layout';
import UnderConstruction from "./UnderConstruction/UnderConstruction";
import Chart from "./Chart/Chart";
import MainPage from "./Admin/Pages/Main/MainPage";
import PayersPage from "./Admin/Pages/Payers/PayersPage";
import PaymentsPage from "./Admin/Pages/Payments/PaymentsPage";
import SettingsPage from "./Admin/Pages/Settings/SettingsPage";
import DebugPage from "./Admin/Pages/Debug/DebugPage";
import EditPayerPage from "./Admin/Pages/Payers/EditPayerPage";
import WebApp from "./WebApp/Main/WebApp";
import { TelegramProvider } from "./telegram/TelegramProvider";
import NewPayerPage from "./Admin/Pages/Payers/NewPayerPage";
import NoFunctionalityPage from "./Admin/Pages/NoFunctionality/NoFunctionalityPage";

const router = createBrowserRouter([
    {
        path: "/",
        Component: UnderConstruction
    },
    {
        path: "/chart",
        Component: Chart
    },
    {
        Component: AdminApp,
        children: [
            {
                path: '/admin',
                Component: Layout,
                children: [
                    {
                        path: '/admin',
                        Component: MainPage
                    },
                    {
                        path: '/admin/payers',
                        Component: PayersPage
                    },
                    {
                        path: '/admin/payers/new',
                        Component: NewPayerPage
                    },
                    {
                        path: '/admin/payers/:payerId',
                        Component: EditPayerPage
                    },
                    {
                        path: '/admin/payments',
                        Component: () => NoFunctionalityPage({ title: "Payments" })
                    },
                    {
                        path: '/admin/settings',
                        Component: () => NoFunctionalityPage({ title: "Settings" })
                    },
                    {
                        path: '/admin/debug',
                        Component: DebugPage
                    },
                ]
            },
        ]
    },
    {
        path: "/webapp",
        element: (
            <TelegramProvider>
                <WebApp />
            </TelegramProvider>
        )
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
