import React from "react";
import Box from "@mui/material/Box";
import {
    Checkbox,
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import FiberNewIcon from '@mui/icons-material/FiberNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ErrorIcon from '@mui/icons-material/Error';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import {Moment} from "moment";
import ModalWindow from "./ModalWindow";
import { IPaymentsListProps } from "./IPaymentsListProps";

export default function PaymentsList(props: IPaymentsListProps) {

    // Todo: move interface out
    // Todo: change modal window to alerting one

    const [confirmationModalWindowActive, setConfirmationModalWindowActive] =
        React.useState(false);

    const [indexToRemove, setIndexToRemove] =
        React.useState<number>(-1);

    const getStatusIcon = (statusRaw: string | undefined) => ({
        "created": <AccessTimeIcon color={"info"}/>,
        "paid": <CheckCircleIcon color={"success"}/>,
        "rejected": <ThumbDownIcon color={"error"}/>,
        "awaitsApprove": <ThumbUpOffAltIcon color={"warning"}/>,
        "overdue": <ErrorIcon color={"error"}/>,
    }[statusRaw ?? ""] || null);

    const getStatusName = (statusRaw: string | undefined) => ({
        "created": <Typography noWrap color={"info"}>Not paid</Typography>,
        "paid": <Typography noWrap color={"success"}>Paid</Typography>,
        "rejected": <Typography noWrap color={"error"}>Rejected</Typography>,
        "awaitsApprove": <Typography noWrap color={"warning"}>On approve</Typography>,
        "overdue": <Typography noWrap color={"error"}>Overdue</Typography>,
    }[statusRaw ?? ""] || null);

    function renderDate(date: Moment | undefined) {
        return (
            <Box display="flex">
                <Typography noWrap>
                    {date?.format("DD.MM.YYYY")}
                </Typography>
                <Typography color="textDisabled" noWrap>
                    &nbsp;–&nbsp;
                    {date?.clone().add(4, "day").format("DD.MM.YYYY")}
                </Typography>

            </Box>
        );
    }

    function removePaymentBeforeConfirm(index: number) {
        setIndexToRemove(index);
        setConfirmationModalWindowActive(true);
    }

    function removePaymentAfterConfirm() {
        const index = indexToRemove;
        setConfirmationModalWindowActive(false);
        setIndexToRemove(-1);

        props?.onRemove?.(index);
    }

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell><Typography><b>Period</b></Typography></TableCell>
                            <TableCell><Typography><b>Status</b></Typography></TableCell>
                            <TableCell align={"right"}><Typography><b>Amount</b></Typography></TableCell>
                            <TableCell align={"center"}><Typography><b>Marked</b></Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (!(props.isLoading ?? false)) &&
                            props.payments.map((p, index) => (
                                <TableRow style={{backgroundColor: (props.selectedIndex === index ? "#ffe557" : "transparent")}}
                                          key={index}
                                          sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell>
                                        <Box display="flex" columnGap={1} alignItems={"stretch"}>
                                            <Typography display={"flex"}>
                                                { p.id == null ? <FiberNewIcon color={"primary"}/> : "" }
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{renderDate(p.date)}</TableCell>
                                    <TableCell>
                                        <Box display="flex" columnGap={1} alignItems="center">
                                            {getStatusIcon(p.status)}
                                            {getStatusName(p.status)}
                                        </Box>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <Typography>{p.amount}</Typography>
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        <Checkbox disabled checked={p.marked ?? false}/>
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <IconButton onClick={() => props?.onEdit?.(index)}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={() => removePaymentBeforeConfirm(index)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                        }

                        {
                            (props.isLoading ?? false) &&
                            <>
                                <SkeletonTableRow />
                                <SkeletonTableRow />
                                <SkeletonTableRow />
                            </>
                        }

                        {
                            props?.payments?.length === 0 &&
                            (!(props.isLoading ?? false)) &&
                            <NoPaymentsTableRow />
                        }

                        <TableRow>
                            <TableCell colSpan={5}>
                                {
                                    !(props.isLoading ?? false) &&
                                    props.error != null &&
                                    props.error !== "" &&
                                    <Box display="flex" flexDirection="row" gap={1}>
                                        <ErrorIcon color="error" />
                                        <Typography color="error">{props.error ?? ""}</Typography>
                                    </Box>
                                }
                            </TableCell>
                            <TableCell align={"right"}>
                                <Box display="flex" justifyContent="flex-end">
                                    <IconButton color="success"
                                                onClick={() => props.onNew?.()}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>

                    </TableBody>

                </Table>

            </TableContainer>

            <ModalWindow open={confirmationModalWindowActive}
                         onClose={() => setConfirmationModalWindowActive(false)}
                         onSubmit={() => removePaymentAfterConfirm()} />

        </Box>
    );

    function SkeletonTableRow() {
        return (
            <TableRow>
                <TableCell><Skeleton><Typography>war</Typography></Skeleton></TableCell>
                <TableCell><Skeleton><Typography>2022-02-24&nbsp;–&nbsp;2025-01-10</Typography></Skeleton></TableCell>
                <TableCell><Skeleton><Typography>I S  S T I L L</Typography></Skeleton></TableCell>
                <TableCell align="right"><Skeleton style={{display: "inline-block"}}><Typography>GOING</Typography></Skeleton></TableCell>
                <TableCell align="center"><Skeleton style={{display: "inline-block"}}><Typography>:((</Typography></Skeleton></TableCell>
                <TableCell>
                    <Box display="flex" gap={2}>
                        <Skeleton style={{display: "inline-block"}}><Typography>ICO</Typography></Skeleton>
                        <Skeleton style={{display: "inline-block"}}><Typography>ICO</Typography></Skeleton>
                    </Box>
                </TableCell>
            </TableRow>
        );
    }

    function NoPaymentsTableRow() {
        return (
            <TableRow>
                <TableCell colSpan={6} height="10em" align="center">
                    <Typography lineHeight={4} color="textDisabled">no payments</Typography>
                </TableCell>
            </TableRow>
        );
    }
}
