import * as React from "react";
import Typography from "@mui/material/Typography";
import { PageContainer } from "@toolpad/core/PageContainer";

export default function MainPage() {
    return (
        <PageContainer title={""} breadcrumbs={[]}>
            <Typography variant="h4">
                Welcome to admin page!
            </Typography>
            <br/>
            <Typography>
                You can't do much things here, but more features are coming!
            </Typography>
            <br/>
            <Typography>
                I hope in the nearest feature you'll find here such features as statistics, editing everything, system
                settings <b>payments that need approve</b> or maybe even <b>manual payments checks.</b>
            </Typography>
            <br/>
            <Typography>
                As for now, you can only <a href="/admin/payers">list your payers</a>, <a href="/admin/payers/new">create new ones</a> or modify existing ones.
            </Typography>
            <br/>
            <Typography>
                Well, good luck and have a good day!
            </Typography>
        </PageContainer>
    );
};
