import * as React from 'react';
import Typography from '@mui/material/Typography';
import { PageContainer } from "@toolpad/core/PageContainer";
import PayerEditor from "../Payers/PayerEditor/PayerEditor";

export default function DebugPage() {

    // Todo: remove everything from here

    return (
        <PageContainer title="Debug" breadcrumbs={[]}>
            <Typography>There is some hidden functionality in this section</Typography>
        </PageContainer>
    );

    //return (
    //    <PageContainer title="Testing payer editor" breadcrumbs={[]}>
    //        <Typography>Here is a component for editing payer</Typography>
    //        <PayerEditor mode="edit"
    //                     payer={{
    //                        payerId: "SOMEVAL2"
    //                     }} />
    //        <hr />
    //        <Typography>Here is a component for creating new payer</Typography>
    //        <PayerEditor mode="new" />
    //    </PageContainer>
    //);
};
