import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { AppProvider } from '@toolpad/core/react-router-dom';
import type {Authentication, Navigation, Session} from '@toolpad/core/AppProvider';
import BugReportIcon from "@mui/icons-material/BugReport";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {AuthProvider, AuthResponse, SignInPage} from "@toolpad/core";
import {useState} from "react";
import {Api} from "../api/api";

const navigation: Navigation = [
    {
        kind: "header",
        title: "Entities",
    },
    {
        segment: "admin",
        title: 'Dashboard',
        icon: <DashboardIcon />,
    },
    {
        segment: "admin/payers",
        pattern: "admin/payers{/:payerId}?",
        title: "Payers",
        icon: <PeopleIcon />
    },
    {
        segment: "admin/payments",
        title: "Payments",
        icon: <PaymentsIcon />,
    },
    {
        kind: "divider",
    },
    {
        kind: "header",
        title: "System",
    },
    {
        segment: "admin/settings",
        title: "Settings",
        icon: <SettingsIcon />
    },
    {
        segment: "admin/debug",
        title: "Debug",
        icon: <BugReportIcon />,
    },
];

const branding = {
    title: "GoToIT admin",
};

export default function AdminApp() {

    const [token, setToken] = useState<string | null>(localStorage.getItem("token"));

    async function signIn(provider: AuthProvider, formData: FormData) : Promise<AuthResponse> {

        const username = formData.get("email")?.toString() ?? "";
        const password = formData.get("password")?.toString() ?? "";

        try {
            const response = await Api.Login(username, password);
            localStorage.setItem("token", response.token);
            localStorage.setItem("login", username);
            localStorage.setItem("name", response.name);
            setToken(response.token);
        } catch (Error) {
            return { error: "Unsuccessful sign-in" }
        }

        return {};
    }

    // Todo: implement sign-in, sign-out +username and pics?

    function signIn2() {

    }

    function signOut2() {
        localStorage.removeItem("token");
        setToken(null);
        window.location.replace("/admin")
    }

    const AUTHENTICATION: Authentication = {
        signIn: signIn2,
        signOut: signOut2,
    };

    const SESSION: Session = {
        user: {
            id: localStorage.getItem("login"),
            name: localStorage.getItem("name"),
            //image: "https://d2ph5fj80uercy.cloudfront.net/04/cat4349.jpg",
            email: localStorage.getItem("login"),
        }
    }

    // Todo: autocomplete doesn't work, probably we need to implement custom sign-in component

    return (
        <AppProvider navigation={navigation}
                     branding={branding}
                     authentication={AUTHENTICATION}
                     session={SESSION}>
            {
                token == null &&
                <SignInPage
                    signIn={signIn}
                    providers={[{ id: "credentials", name: "Email and Password" }]}
                    slotProps={{
                        emailField: { autoFocus: true, autoComplete: "email" },
                        passwordField: { autoComplete: "current-password" }
                    }}
                />
            }
            {
                token != null &&
                <Outlet />
            }
        </AppProvider>
    );
};
