import * as React from "react";
import {useActivePage} from "@toolpad/core";
import {PageContainer} from "@toolpad/core/PageContainer";
import {useParams} from "react-router-dom";
import PayerEditor from "./PayerEditor/PayerEditor";

export default function EditPayerPage() {

    // Todo: wrong id or not existing
    // Todo: payer name?

    const { payerId } = useParams();

    const activePage = useActivePage();

    const path = `${activePage!.path}/${payerId}`;

    const breadcrumbs = [...activePage!.breadcrumbs, { title: payerId ?? "", path: path }];

    return (
        <PageContainer title="Payer view"
                       breadcrumbs={breadcrumbs}>
            <PayerEditor mode="edit"
                         payer={{ payerId: payerId! }} />
        </PageContainer>
    );
};
