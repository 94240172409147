import * as React from 'react';
import PayersList from "./PayersList/PayersList";
import {PageContainer} from "@toolpad/core/PageContainer";

export default function PayersPage() {
    return (
        <PageContainer>
            <PayersList />
        </PageContainer>
    );
};
