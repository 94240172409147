import React, { useEffect, useState } from "react";
import { Api } from "../../../../api/api";
import { PayerModel } from "../../../../api/models/payer_model";
import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridPaginationInitialState,
} from "@mui/x-data-grid";
import {Alert, Button} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';

function PayersList() {

    const navigate = useNavigate();

    const [users, setUsers] = useState<PayerModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const users = await Api.GetAllPayers();
            setUsers(users.payers);
            setIsLoading(false);
        };
        fetchData().catch(console.error);
    }, []);


    const columns: GridColDef<(typeof rows)[number]>[] = [
        {
            field: "payerId",
            headerName: "ID",
            type: "string",
            resizable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            sortable: false,
            //width: 70,
            editable: false,
            renderCell: params => (<span style={{fontFamily: "monospace"}}>{params.value}</span>),
        },
        {
            field: "fullName",
            headerName: "Name",
            type: "string",
            valueGetter: (value, row) => `${row.lastName} ${row.firstName}`,
            editable: false,
            width: 220,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            cellClassName: 'actions',
            resizable: false,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={_ => navigateToPayer(params.row.payerId)}
                    />
                ];
            },
        }
    ];

    const rows = (users ?? []);

    const paginationInitialState : GridPaginationInitialState = {
        paginationModel: {
            pageSize: 10,
        }
    }

    function navigateToNewPayer() {
        navigate("/admin/payers/new");
    }

    function navigateToPayer(payerId: string) {
        navigate(`/admin/payers/${payerId}`);
    }

    return (
        <div className="PayersList">
            <Box component="section" sx={{ py: 2 }}>
                <Button variant="contained" startIcon={<AddCircleIcon />} onClick={navigateToNewPayer}>
                    New payer
                </Button>
            </Box>
            <div>
                <DataGrid
                    loading={isLoading}
                    slotProps={{
                        loadingOverlay: {
                            variant: "skeleton",
                            noRowsVariant: "circular-progress",

                        },
                    }}
                    rows={rows}
                    columns={columns}
                    autosizeOptions={{
                        columns: ["payerId", "fullName"],
                        includeOutliers: true,
                        includeHeaders: true,
                    }}
                    initialState={{ pagination: paginationInitialState }}
                    pageSizeOptions={[5, 10]}
                    getRowId={x => x.payerId}
                    sx={{ border: 0 }} />
            </div>
        </div>
    );
}

export default PayersList;
