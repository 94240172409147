import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import React from "react";
import {IAlertModalWindowProps} from "./IAlertModalWindowProps";

export default function AlertModalWindow(props: IAlertModalWindowProps) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'background.paper',
        boxShadow: 24,
        p: 3,
        outline: "none",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        rowGap: 3
    };

    return (
        <Modal open={props.open ?? true}
               onClose={() => props?.onClose?.()}>

            <Box sx={style}>

                <Typography variant="h6" component="h2" color="primary">
                    {props.headerText}
                </Typography>

                <Typography>
                    {props.bodyText}
                </Typography>

                <Box display="flex" columnGap={2} marginTop={2}>

                    <Button variant="contained"
                            onClick={() => props.onSubmit?.()}>
                        {props.submitButtonCaption ?? "OK"}
                    </Button>

                    {
                        (props.showCloseButton ?? true) &&
                        <Button variant="outlined"
                                onClick={() => props.onClose?.()}>
                            Cancel
                        </Button>
                    }
                </Box>
            </Box>
        </Modal>
    );
}

