import React from "react";
import Box from "@mui/material/Box";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment, {Moment} from "moment";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {IPaymentEditorProps} from "./IPaymentEditorProps";
import {PaymentStatus} from "./IPaymentModel";

export default function PaymentEditor(props: IPaymentEditorProps) {

    const [id, setId] =
        React.useState<string>("");

    const [shortId, setShortId] =
        React.useState<string>("");

    const [date, setDate] =
        React.useState<Moment>(moment(""));

    const [amount, setAmount] =
        React.useState<string>("");

    const [status, setStatus] =
        React.useState<PaymentStatus>("created");

    const [marked, setMarked] =
        React.useState<boolean>(false);

    React.useEffect(() => {
        setId(props.payment?.id ?? "");
        setShortId(props.payment?.shortId ?? "");
        setDate(moment(props.payment?.date ?? ""));
        setAmount(props.payment?.amount?.toString() ?? "");
        setStatus(props.payment?.status ?? "created");
        setMarked(props.payment?.marked ?? false);
    }, [
        props.payment?.amount,
        props.payment?.date,
        props.payment?.id,
        props.payment?.marked,
        props.payment?.shortId,
        props.payment?.status
    ]);

    function validateAmount() {
        return amount !== "" && (!Number.isNaN(+amount) && +amount >= 0);
    }

    function validateAll(): boolean {
        return date != null && date.isValid() && validateAmount();
    }

    function onCreateClick() {
        if (props.mode === "new") {
            props.onCreatePayment?.({
                date: date ?? undefined,
                amount: +amount,
                status: status,
                marked: marked
            });
        }
    }

    function onUpdateClick() {
        if (props.mode === "edit") {
            props.onUpdatePayment?.({
                id: (id !== "") ? id : undefined,
                shortId: (shortId !== "") ? shortId : undefined,
                date: date,
                amount: +amount,
                status: status,
                marked: marked
            });
        }
    }

    function onClearOrCancelClick() {

        // Clearing
        if (props.mode === "new") {
            setStatus("created");
            setDate(moment(""));
            setAmount("");
            setMarked(false);
            return;
        }

        // Cancelling
        if (props.mode === "edit") {
            props.onCancelEditing?.();
            return;
        }
    }

    return (

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">

            <Paper variant="outlined" sx={{display: "flex", flexDirection: "column", rowGap: 2, padding: 2}}>

                <Box display="flex" columnGap={2}>
                    <TextField label="Payment ID"
                               fullWidth
                               disabled
                               size="small"
                               variant="standard"
                               value={id} />
                    <TextField label="Payment short ID"
                               fullWidth
                               disabled
                               size="small"
                               variant="standard"
                               value={shortId} />
                </Box>

                <FormControl variant="standard" sx={{ minWidth: 120, width: "100%" }}>
                    <InputLabel>Payment status</InputLabel>
                    <Select value={status}
                            onChange={e => setStatus(e.target.value as PaymentStatus)}
                            label="Payment status">
                        <MenuItem disabled={!(props.mode === "new" || props.mode === "edit")} value={"created"}>Not paid</MenuItem>
                        <MenuItem disabled={!(                        props.mode === "edit")} value={"awaitsApprove"}>Awaits approve</MenuItem>
                        <MenuItem disabled={!(                        props.mode === "edit")} value={"rejected"}>Rejected</MenuItem>
                        <MenuItem disabled={!(props.mode === "new" || props.mode === "edit")} value={"paid"}>Paid</MenuItem>
                        <MenuItem disabled={!(                        props.mode === "edit")} value={"overdue"}>Overdue</MenuItem>
                    </Select>
                </FormControl>

                <Box display="flex" columnGap={2}>
                    <DatePicker slotProps={{ textField: { size: "small", variant: "standard", fullWidth: true } }}
                                label="Payment date"
                                value={date}
                                onChange={e => setDate(moment(e ?? ""))} />
                    <DatePicker slotProps={{ textField: { size: "small", variant: "standard", fullWidth: true } }}
                                disabled={true}
                                label="Should be paid until"
                                value={date?.clone().add(4, "day")} />
                </Box>

                <TextField label="Amount"
                           error={!validateAmount()}
                           fullWidth
                           size="small"
                           variant="standard"
                           value={amount}
                           onChange={e => setAmount(e.target.value)} />

                <FormControlLabel control={<Checkbox checked={marked} />}
                                  label="Was marked while approvement"
                                  onChange={(_, checked) => setMarked(checked)}
                                  disabled={props.mode === "new"} />

                <Box display="flex" flexDirection="row" columnGap={2}>
                    {
                        props.mode === "new" &&
                        <>
                            <Button variant="contained"
                                    disabled={!validateAll()}
                                    onClick={() => onCreateClick()}>
                                Create
                            </Button>
                            <Button variant="outlined"
                                    onClick={() => onClearOrCancelClick()}>
                                Clear
                            </Button>
                        </>
                    }
                    {
                        props.mode === "edit" &&
                        <>
                            <Button variant="contained"
                                    disabled={!validateAll()}
                                    onClick={() => onUpdateClick()}>
                                Update
                            </Button>
                            <Button variant="outlined"
                                    onClick={() => onClearOrCancelClick()}>
                                Cancel
                            </Button>
                        </>
                    }
                </Box>

            </Paper>

        </LocalizationProvider>
    );
}
