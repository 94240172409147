import "./Splash.css";

export enum SplashScreenVariant {
    Loading,
    Denied,
    Error,
    NoManager
}

interface ISplashProps {
    variant: SplashScreenVariant;
}

export default function Splash(props: ISplashProps) {

    const style = (() => {
        switch (props.variant) {
            case SplashScreenVariant.Loading: return "";
            case SplashScreenVariant.Denied: return "Splash--Denied";
            case SplashScreenVariant.Error: return "Splash--Error";
            case SplashScreenVariant.NoManager: return "Splash--Denied";
        }
    })();

    const message = (() => {
        switch (props.variant) {
            case SplashScreenVariant.Loading: return "загружаем";
            case SplashScreenVariant.Denied: return "доступ запрещён";
            case SplashScreenVariant.Error: return "мы сломались :(";
            case SplashScreenVariant.NoManager: return "доступ для менеджера не предусмотрен";
        }
    })();

    const variant = props.variant;

    return (
        <div className={`Splash ${style}`}>
            <div className="Splash__Logo">
                <div className="Splash__Eggs" hidden={variant !== SplashScreenVariant.Error}/>
                <span className="Splash__LogoText" hidden={variant !== SplashScreenVariant.Loading}>
                    <b>ВОЙТИ</b>В<b>АЙТИ</b>
                </span>
                <div className="Splash__Denied" hidden={(variant !== SplashScreenVariant.Denied) && (variant !== SplashScreenVariant.NoManager)} />
            </div>
            <span className="Splash__Message">{message}</span>
        </div>
    );
}
