import { IPayerEditorProps } from "./IPayerEditorProps";
import React, {SyntheticEvent, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import { Alert, Chip, Skeleton, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { IPaymentModel, PaymentStatus } from "./IPaymentModel";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Api } from "../../../../api/api";
import { IPaymentModel as IApiPaymentModel } from "../../../../api/models/payment_model";
import { IUpdatePayerRequest } from "../../../../api/models/update_payer_request";
import { CreatePayerRequest } from "../../../../api/models/create_payer_request";
import PaymentEditor from "./PaymentEditor";
import PaymentsList from "./PaymentsList";
import "moment/locale/ru";
import AlertModalWindow from "./AlertModalWindow";
import {useNavigate} from "react-router-dom";

export default function PayerEditor(props: IPayerEditorProps) {

    // Todo: if no changes then do not fire the button
    // Todo: error when something goes wrong
    // Todo: deactivated payers
    // Todo: INPORTANT no payment information attached
    // Todo: IMOORTANT no payer status (registered or not)
    // Todo: validate payer immediately (new and edit)
    // Todo: days const

    const navigate = useNavigate();

    const [payerIdLoading, setPayerIdLoading] =
        React.useState<boolean>(true);

    const [payerLoading, setPayerLoading] =
        React.useState<boolean>(true);

    const [groupsLoading, setGroupsLoading] =
        React.useState<boolean>(true);

    const [paymentsLoading, setPaymentsLoading] =
        React.useState<boolean>(props.mode === "edit");

    const [validationEnabled, setValidationEnabled] =
        React.useState<boolean>(false);

    const [payerId, setPayerId] =
        React.useState<string>(props.payer?.payerId ?? "");

    const [lastName, setLastName] =
        React.useState<string>(props.payer?.lastName ?? "");

    const [firstName, setFirstName] =
        React.useState<string>(props.payer?.firstName ?? "");

    const [group, setGroup] =
        React.useState<string>(props.payer?.group ?? "");

    const [percent, setPercent] =
        React.useState<string>(props.payer?.percent?.toString() ?? "");

    const [salary, setSalary] =
        React.useState<string>(props.payer?.salary?.toString() ?? "");

    const [paymentDetails, setPaymentDetails] =
        React.useState<string>(props.payer?.paymentDetails?.toString() ?? "");

    const [paymentDetailsInfo, setPaymentDetailsInfo] =
        React.useState<string>(props.payer?.paymentDetailsInfo?.toString() ?? "");

    const [groups, setGroups] =
        React.useState<string[]>([]);

    const [payments, setPayments] =
        React.useState<IPaymentModel[]>([]);

    const [error, setError] =
        useState<string>("");

    const [showPaymentEditor, setShowPaymentEditor] =
        useState<boolean>(false);

    const [paymentEditorMode, setPaymentEditorMode] =
        useState<"new" | "edit">("new");

    const [paymentsListIndex, setPaymentsListIndex] =
        useState<number>(-1);

    const [alertShown, setAlertShown] =
        useState<boolean>(false);

    async function LoadPayerId() {
        setPayerIdLoading(true);
        const response = await Api.GetSuggestedPayerId();
        setPayerId(response.payer_id);
        setPayerIdLoading(false);
    }

    async function LoadGroups() {
        setGroupsLoading(true);
        const response = await Api.GetGroups();
        setGroups(response.groups);
        setGroupsLoading(false);
    }

    async function LoadPayer() {
        setPayerLoading(true);
        const response = await Api.GetPayer(props.payer?.payerId ?? "");
        // Todo: response.payers will be empty if no payer found
        // And later payments fetching goes 404
        const payer = response.payers[0];
        setLastName(payer.lastName);
        setFirstName(payer.firstName);
        setGroup(payer.group);
        setPercent(payer.percent.toString());
        setSalary(payer.salary.toString());
        setPaymentDetails(payer.paymentDetails);
        setPaymentDetailsInfo(payer.paymentDetailsInfo);
        setPayerLoading(false);
    }

    async function LoadPayments() {

        setPaymentsLoading(true);

        const response = await Api.GetPayments(props.payer?.payerId ?? "");

        const payments = response.payments.map<IPaymentModel>(p => ({
            id: p.id,
            shortId: p.shortId,
            date: moment(p.date),
            amount: p.amount,
            marked: p.marked,
            status: p.status as PaymentStatus
        }));

        setPayments(payments);
        setPaymentsLoading(false);
    }

    useEffect(() => {
        if (props.mode === "new") {
            LoadPayerId().catch(_ => setError("Payer ID fetch error"));
        }
        if (props.mode === "edit") {
            LoadPayer().catch(_ => setError("Payer fetch error"));
            LoadPayments().catch(_ => setError("Payments fetch error"));
        }
        LoadGroups().catch(_ => setError("Groups fetch error"));
    }, [props.mode]);

    function onGroupChipClick(e: SyntheticEvent) {
        const chipText = (e.target as HTMLSpanElement).innerText;
        setGroup(chipText);
    }

    function validateFirstName() {
        return validationEnabled
            ? firstName !== ""
            : true;
    }

    function validateLastName() {
        return validationEnabled
            ? lastName !== ""
            : true;
    }

    function validateGroup() {
        return validationEnabled
            ? group !== ""
            : true;
    }

    function validateSalary() {
        return validationEnabled
            ? salary !== "" && (!Number.isNaN(+salary) && +salary >= 0)
            : true;
    }

    function validatePercent() {
        return validationEnabled
            ? percent !== "" && (!Number.isNaN(+percent) && +percent >= 0)
            : true;
    }

    function validatePayments(): string {

        if (payments.length === 0) {
            return "Payer has no payments";
        }

        const datesOnly = payments.map(p => p.date!.unix());
        if (new Set(datesOnly).size !== datesOnly.length) {
            return "Payer can't have to payments at the same day";
        }

        return "";
    }

    async function onSubmit() {

        setValidationEnabled(true);

        if (!validateAll()) {
            return;
        }

        if (props.mode === "new") {

            const data: CreatePayerRequest = {
                payerId: payerId,
                lastName: lastName,
                firstName: firstName,
                groupName: group,
                salary: +salary,
                percent: +percent,
                paymentDetails: paymentDetails,
                paymentDetailsInfo: paymentDetailsInfo,
                payments: payments.map<IApiPaymentModel>(p => ({
                    id: p.id!,
                    shortId: p.shortId!,
                    date: p.date!.toISOString(),
                    amount: p.amount!,
                    marked: p.marked!,
                    status: p.status!
                }))
            };

            await Api.CreatePayer(data);

            setAlertShown(true);

            return;
        }

        if (props.mode === "edit") {

            const data: IUpdatePayerRequest = {
                payerId: payerId,
                lastName: lastName,
                firstName: firstName,
                group: group,
                salary: +salary,
                percent: +percent,
                paymentDetails: paymentDetails,
                paymentDetailsInfo: paymentDetailsInfo,
                payments: payments.map<IApiPaymentModel>(p => ({
                    id: p.id!,
                    shortId: p.shortId!,
                    date: p.date!.toISOString(),
                    amount: p.amount!,
                    marked: p.marked!,
                    status: p.status!
                }))
            };

            await Api.UpdatePayer(data);

            setAlertShown(true);

            return;
        }
    }

    function onNewPaymentClick() {
        setShowPaymentEditor(true);
        setPaymentsListIndex(-1);
        setPaymentEditorMode("new");
    }

    function onNewPaymentCreate(newPayment: IPaymentModel) {

        const newPayments = [...payments, newPayment];
        newPayments.sort((a, b) => (a.date?.unix() ?? 0) - (b.date?.unix() ?? 0));

        setPayments(newPayments);
    }

    function onPaymentEditClick(index: number) {
        setShowPaymentEditor(true);
        setPaymentsListIndex(index);
        setPaymentEditorMode("edit");
    }

    function onPaymentRemoveClick(index: number) {

        const newPayments = [...payments];
        newPayments.splice(index, 1);

        setShowPaymentEditor(false);
        setPaymentsListIndex(-1);
        setPaymentEditorMode("new");
        setPayments(newPayments);
    }

    function onUpdatePayment(updatedPayment: IPaymentModel) {

        const index = paymentsListIndex;

        const newPayments = [...payments];
        newPayments.splice(index, 1);
        newPayments.push(updatedPayment);
        newPayments.sort((a, b) => (a.date?.unix() ?? 0) - (b.date?.unix() ?? 0));

        setShowPaymentEditor(false);
        setPaymentsListIndex(-1);
        setPaymentEditorMode("new");
        setPayments(newPayments);
    }

    function onCancelEditingPayment() {
        setShowPaymentEditor(false);
        setPaymentsListIndex(-1);
        setPaymentEditorMode("new");
    }

    function validateAll() {
        return (
            validateFirstName() &&
            validateLastName() &&
            validateGroup() &&
            validateSalary() &&
            validatePercent() &&
            (validatePayments() === ""));
    }

    return (

        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">

            <Box display="flex" flexDirection="column" rowGap={2} paddingBottom={6}>

                <TextField label="Payer ID"
                           fullWidth
                           variant="filled"
                           error={error !== ""}
                           size="small"
                           contentEditable={false}
                           value={payerId} />

                <TextField label="Last name"
                           fullWidth
                           variant="standard"
                           size="small"
                           disabled={props.mode === "edit" && payerLoading}
                           onChange={(event) => setLastName(event.target.value)}
                           error={!validateLastName()}
                           value={lastName} />

                <TextField label="First name"
                           fullWidth
                           variant="standard"
                           size="small"
                           disabled={props.mode === "edit" && payerLoading}
                           onChange={(event) => setFirstName(event.target.value)}
                           error={!validateFirstName()}
                           value={firstName} />

                <Box display="flex" flexWrap="wrap" gap={1}>

                    <TextField label="Group"
                               fullWidth
                               variant="standard"
                               size="small"
                               disabled={props.mode === "edit" && payerLoading}
                               onChange={(event) => setGroup(event.target.value)}
                               error={!validateGroup()}
                               value={group} />

                    {
                        groupsLoading ? (
                            <>
                                <Skeleton><Chip size="small" label="Die" /></Skeleton>
                                <Skeleton><Chip size="small" label="Putin" /></Skeleton>
                                <Skeleton><Chip size="small" label="Die" /></Skeleton>
                            </>
                        ) : (
                            <>
                                {
                                    groups.slice(0, 10).map((v, index) =>
                                        <Chip disabled={props.mode === "edit" && payerLoading}
                                              key={index}
                                              size="small"
                                              label={v}
                                              onClick={onGroupChipClick} />
                                    )
                                }
                            </>
                        )
                    }
                </Box>

                <TextField label="Salary"
                           fullWidth
                           variant="standard"
                           size="small"
                           disabled={props.mode === "edit" && payerLoading}
                           onChange={(event) => setSalary(event.target.value)}
                           error={!validateSalary()}
                           value={salary} />

                <TextField label="Percent"
                           fullWidth
                           variant="standard"
                           size="small"
                           disabled={props.mode === "edit" && payerLoading}
                           onChange={(event) => setPercent(event.target.value)}
                           error={!validatePercent()}
                           value={percent} />

                <TextField label="Payment details"
                           variant="standard"
                           fullWidth
                           value={paymentDetails}
                           disabled={props.mode === "edit" && payerLoading}
                           onChange={(event) => setPaymentDetails(event.target.value)}
                           multiline
                           rows={3} />

                <TextField label="Payment details info"
                           variant="standard"
                           fullWidth
                           value={paymentDetailsInfo}
                           disabled={props.mode === "edit" && payerLoading}
                           onChange={(event) => setPaymentDetailsInfo(event.target.value)}
                           multiline
                           rows={3} />

                <Box sx={{display: "flex", flexDirection: "column", rowGap: 2, my: 1}}>

                    <PaymentsList selectedIndex={paymentsListIndex}
                                  isLoading={paymentsLoading}
                                  payments={payments}
                                  error={validatePayments()}
                                  onNew={onNewPaymentClick}
                                  onEdit={onPaymentEditClick}
                                  onRemove={onPaymentRemoveClick} />

                    {
                        showPaymentEditor &&
                        <PaymentEditor mode={paymentEditorMode}
                                       payment={payments[paymentsListIndex]}
                                       onCreatePayment={onNewPaymentCreate}
                                       onUpdatePayment={(payment) => onUpdatePayment(payment)}
                                       onCancelEditing={() => onCancelEditingPayment()} />
                    }

                </Box>

                {
                    error !== "" &&
                    <Alert severity="error">{error}</Alert>
                }

                <Box>

                    <LoadingButton
                        color="primary"
                        onClick={onSubmit}
                        loading={
                            (props.mode === "new" && (payerIdLoading || groupsLoading)) ||
                            (props.mode === "edit" && (payerLoading || groupsLoading || paymentsLoading))
                        }
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        disabled={!validateAll()}
                        variant="contained">
                            {
                                props.mode === "new" ? "Create payer" : props.mode === "edit" ? "Update payer" : ""
                            }
                    </LoadingButton>

                </Box>

                <AlertModalWindow open={alertShown}
                                  onClose={(
                                      props.mode === "edit"
                                          ? (() => {
                                              setAlertShown(false);
                                          })
                                          : (() => {
                                              setAlertShown(false);
                                              navigate("/admin/payers");
                                          })
                                  )}
                                  onSubmit={(
                                      props.mode === "edit"
                                          ? (() => {
                                              setAlertShown(false);
                                          })
                                          : (() => {
                                              setAlertShown(false);
                                              navigate("/admin/payers");
                                          })
                                  )}
                                  showCloseButton={false}
                                  headerText={ props.mode === "edit" ? "Payer updated" : "Payer created" }
                                  bodyText={(
                                      props.mode === "edit"
                                          ? "The payer has been updated successfully"
                                          : "The payer has been created"
                                  )} />

            </Box>

        </LocalizationProvider>
    );
}
