import * as React from 'react';
import Typography from '@mui/material/Typography';
import {PageContainer} from "@toolpad/core/PageContainer";

export interface INoFunctionalityPage {
    title: string;
}

export default function NoFunctionalityPage(props: INoFunctionalityPage) {
    return (
        <PageContainer title={props.title} breadcrumbs={[]}>
            <Typography>There is no functionality for this section yet.</Typography>
        </PageContainer>
    );
};
