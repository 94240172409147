import React from "react";
import IPayerInfoProps from "./IPayerInfoProps";
import "./PayerInfo.css";

export function PayerInfo(props: IPayerInfoProps) {
    return (
        <div className="PayerInfo">
            <span>Оклад на руки: <b>{props.salary}₽</b></span>
            <span>Процент с оклада: <b>{props.percent}%</b></span>
            <span>Платежей: <b>{props.paymentsCount}</b></span>
        </div>
    );
}