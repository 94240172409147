import * as React from 'react';
import { useActivePage } from "@toolpad/core";
import { PageContainer } from "@toolpad/core/PageContainer";
import PayerEditor from "./PayerEditor/PayerEditor";

export default function NewPayerPage() {

    const activePage = useActivePage();

    const title = `New payer`;
    const path = `${activePage!.path}/new`;

    const breadcrumbs = [...activePage!.breadcrumbs, { title, path }];

    return (
        <PageContainer title={title} breadcrumbs={breadcrumbs}>
            <PayerEditor mode="new" />
        </PageContainer>
    );
};
