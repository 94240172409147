import Splash, { SplashScreenVariant } from "../Splash/Splash";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import "./Payment.css";
import "./WebApp.css";
import Icon from "@mui/material/Icon";
import { Api } from "../../api/api";
import { GetWebAppUserInfoResponse } from "../../api/models/get_webapp_user_info_response";
import { ITelegramUser } from "../../telegram/ITelegramUser";
import { PayerInfo } from "../PayerInfo/PayerInfo";
import {useTelegram} from "../../telegram/TelegramProvider";

export default function WebApp() {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<GetWebAppUserInfoResponse | null>(null);

    const { user, webApp } = useTelegram();
    //console.log({user, webApp});

    //const user : ITelegramUser = {
    //    id: 42,
    //    first_name: "Гена",
    //    last_name: "Букин",
    //    language_code: "ru",
    //    username: "gena_bukin_81"
    //};

    //const webApp = {
    //    initData: "a"
    //}

    useEffect(() => {

        const fetchData = async () => {

            if (user?.id == null && webApp?.initData == null) {
                console.log("no")
                return;
            }

            setLoading(true);
            const data = await Api.GetWebAppUserInfo(user!.id, webApp!.initData);
            setData(data);
            setLoading(false);
        };

        fetchData().catch(() => {
            setError(true);
        });
    }, [user, webApp]);

    const splashScreenVariant = (() => {

        if (user == null) {
            return SplashScreenVariant.Denied;
        }

        if (error || (data?.error ?? false)) {
            return SplashScreenVariant.Error;
        }

        if (loading) {
            return SplashScreenVariant.Loading;
        }

        if (data?.isManager ?? false) {
            return SplashScreenVariant.NoManager;
        }

        if (data != null && !data!.isRegistered) {
            return SplashScreenVariant.Denied;
        }

        return SplashScreenVariant.Error;
    })();

    return (
        <div className="WebApp">
            {
                (loading || data?.isManager || (!data?.isRegistered)) &&
                <Splash variant={splashScreenVariant}></Splash>
            }
            {
                !loading &&
                data != null &&
                data!.isRegistered &&
                !data!.isManager &&
                <div className="WebApp__Container">
                    <div className="WebApp__Section">
                        <div className="WebApp__Welcome">{`Привет, ${data?.userName}!`}</div>
                    </div>
                    <div className="WebApp__Section">
                        <span className="WebApp__Subtitle">Твои условия:</span>
                        <PayerInfo salary={data!.salary}
                                   percent={data!.percent}
                                   paymentsCount={data!.payments.length} />
                    </div>
                    <div className="WebApp__Section">
                        <span className="WebApp__Subtitle">Твои платежи:</span>
                        <div className="WebApp__PaymentsList">
                            {
                                data!.payments.map((p, index) => (
                                    <Payment key={index} date={p.date} amount={p.amount} state={p.status} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

interface IPaymentProps {
    state: "done" | "awaiting" | "failed" | "planned" | any;
    date: string;
    amount: number;
}

function Payment(props: IPaymentProps) {

    const containerClassName = (() => {
        switch (props.state) {
            case "done":
                return " Payment--Done";
            case "awaiting": return " Payment--Awaiting";
            case "planned": return " Payment--Planned";
            case "failed": return " Payment--Failed";
            default: return "";
        }
    })();

    const iconClassName = (() => { switch (props.state) {
        case "done": return " Payment__Icon--Done";
        case "awaiting": return " Payment__Icon--Awaiting";
        case "planned": return " Payment__Icon--Planned";
        case "failed": return " Payment__Icon--Failed";
        default: return "";
    }})();

    const iconComponent = (() => { switch (props.state) {
        case "done": return (<CheckCircleIcon />);
        case "awaiting": return (<AccessTimeFilledRoundedIcon/>);
        case "planned": return (<AccessTimeRoundedIcon/>);
        case "failed": return (<ErrorRoundedIcon/>);
        default: return null;
    }})();

    const monthsMap = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
    ]

    const dateString = new Date(props.date + "T00:00:00.000Z");
    const date = `${dateString.getDate()} ${monthsMap[dateString.getMonth()]} ${dateString.getFullYear()}`;
    const amount = `${props.amount}₽`;

    return (
        <div className={`Payment${containerClassName}`}>
            <Icon className={`Payment__Icon${iconClassName}`}>{iconComponent}</Icon>
            <div>{date}</div>
            <div>{amount}</div>
        </div>
    );
}
